html, body, div#root, div.App, div#layout, div#app-body, div#app-body-fullscreen {
  height: 100%;
  display: flex;
  flex-direction: column;
}

div#app-body .MuiPaper-root,
div#app-body-fullscreen .MuiPaper-root {
  background-color: rgb(239, 239, 240);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div#layout div#app-body {
  margin: .5em;
  max-width: 800px; 
}

@media screen and (min-width:800px) {
  div#layout div#app-body {
    margin: auto;
    min-width: 800px;
  }
}

div#layout div#app-body-fullscreen {
  padding: .5em;
}

div.follow-marker h1,
div.follow-marker div.region-text,
div.follow-marker div.iframe-container {
  display: flex;
}

div.follow-marker {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
}

div.follow-marker h1 {
  font-size: 1.3em;
}

div.follow-marker div.region-text {
  font-size: 1em;
}

div.follow-marker div.iframe-container {
  flex-grow: 2;
  width: 100%;
}